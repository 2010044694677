@use 'mixins';
@use '@angular/material' as mat;
@use 'variables';
@use 'colors';

.mat-mdc-icon-button.mat-mdc-icon-button {
	--mdc-icon-button-state-layer-size: 40px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.mat-mdc-button-touch-target {
		@include mixins.equal-size(40px);
	}

	&.mat-mdc-button-base {
		@include mixins.equal-size(40px);
	}
}

.au-btn {
	appearance: none;
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	padding: 0 16px;
	font-family: variables.$secondary-font-family;
	font-size: mixins.px-to-rem(15);
	font-weight: 600;
	min-width: 150px;
	height: 50px;
	box-sizing: border-box;
	transition: all 250ms ease-in-out;
	overflow: hidden;
	cursor: pointer;

	&.rounded {
		min-width: 40px;
		@include mixins.equal-size(40px);
	}
}

.au-mat-btn {
	&-xs {
		height: 32px !important;
		line-height: 22px !important;
		padding: 5px 12px !important;
		font-family: variables.$secondary-font-family;
		font-size: mixins.px-to-rem(12) !important;
		font-weight: 700;
		letter-spacing: 0.04em;
	}

	&-s {
		height: 32px !important;
		line-height: 30px !important;
		padding: 0 8px !important;
		font-size: mixins.px-to-rem(12) !important;
	}

	&-small {
		height: 40px !important;
		line-height: 40px !important;
	}

	&-high {
		height: 50px !important;
	}
}

.au-mat-flat-stroke.au-mat-flat-stroke {
	border: 1px solid colors.$separator;
	border-radius: 8px;
	background-color: colors.$ghost-button-background;
	color: colors.$secondary-text;

	&.secondary {
		border: 1px solid colors.$separator;
		background-color: transparent;
		color: colors.$accent;

		&:hover {
			background-color: rgba(colors.$accent, 0.1);
		}
	}
}

.secondary-btn {
	background: var(--mat-sys-primary-container);
	color: var(--mat-sys-on-primary-container);

	&:hover {
		background-color: colors.$accent !important;
	}

	.mat-ripple-element {
		background-color: rgba(white, 0.1) !important;
	}
}

.error-btn {
	background-color: colors.$error !important;
	color: colors.$black-text !important;

	&:hover {
		background-color: colors.$secondary-error !important;
	}

	.mat-ripple-element {
		background-color: rgba(white, 0.1) !important;
	}
}

.au-nav-buttons-group {
	margin-bottom: 32px;

	.au-btn {
		min-width: inherit;
		height: inherit;
		padding: 12px;
		color: colors.$inactive-tab;
		white-space: nowrap;
		margin-right: 10px;
		font-family: variables.$main-font-family;
		font-size: mixins.px-to-rem(18);
		font-weight: 600;

		&:hover {
			background-color: rgba(colors.$accent, 0.07);
			color: colors.$accent;
		}

		&:last-child {
			margin-right: 0;
		}

		&.active {
			background-color: colors.$accent;
			color: white;

			&:hover {
				background-color: colors.$secondary-text;
			}
		}
	}
}

.reset-btn {
	padding: 6px 14px;
	border-radius: 8px;
	font-family: variables.$main-font-family;
	font-size: mixins.px-to-rem(14);
	line-height: mixins.px-to-rem(22);
	color: colors.$accent;

	.mat-ripple-element {
		background-color: rgba(colors.$accent, 0.2);
	}
}

.add-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	min-width: 152px;
	width: 152px;
	height: 55px;
	::ng-deep .mat-button-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.mat-icon {
		margin-right: 10px;
	}

	span {
		display: inline-block;
		margin-top: 2px;
	}
}

.au-no-letter-spacing.au-no-letter-spacing {
	letter-spacing: 0;
}

.au-add-fab-button {
	border-radius: 100%;
	border: none;
	width: 32px;
	height: 32px;
	display: flex;
	align-content: center;
	justify-content: center;
	cursor: pointer;

	.mat-icon {
		align-self: center;
	}
}

.mat-flat-btn-font-correction {
	.mdc-button__label {
		margin-bottom: -2px;
	}
}

.mat-btn-wrapper-flex {
	.mat-button-wrapper {
		display: flex;
	}
}

.filter-btn {
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 0 8px;
	height: 32px;
	cursor: pointer;
	border: 1px solid colors.$separator;
	border-radius: 8px;
	color: colors.$accent;
	box-sizing: border-box;

	&:hover {
		background-color: rgba(colors.$accent, 0.1);
	}

	span {
		font-family: variables.$secondary-font-family;
		font-size: mixins.px-to-rem(12);
		line-height: mixins.px-to-rem(22);
		letter-spacing: 0.05em;
		font-weight: 700;
		user-select: none;
		text-transform: uppercase;
		margin-bottom: -2px;
	}
}

.secondary-fab {
	@include mat.fab-overrides(
		(
			small-container-color: var(--mat-sys-secondary-container),
			container-color: var(--mat-sys-secondary-container),
		)
	);
}

.surface-fab {
	@include mat.fab-overrides(
		(
			small-container-color: var(--mat-sys-surface-container-high),
			small-foreground-color: var(--mat-sys-primary),
			container-color: var(--mat-sys-surface-container-high),
			foreground-color: var(--mat-sys-primary),
		)
	);
}

.tonal-flat-button {
	@include mat.button-overrides(
		(
			filled-label-text-color: var(--mat-sys-on-secondary-container),
			filled-container-color: var(--mat-sys-secondary-container),
			filled-disabled-label-text-color: var(--mat-sys-on-surface),
		)
	);
}
