@use 'mixins';

.content-container {
	box-sizing: border-box;
	padding: 30px 20px;
	border-radius: 8px;
	background-color: var(--mat-sys-surface-container-lowest);

	&.with-shadow {
		box-shadow: 0 15px 25px rgba(29, 81, 70, 0.05);
	}
}

@include mixins.media-breakpoint-up(sm) {
	.content-container {
		padding: 32px;
	}
}

@include mixins.media-breakpoint-up(lg) {
	.content-container {
		padding: 40px;

		&.small-paddings {
			padding: 32px;
		}
	}
}

.loader-container {
	position: relative;
}

@media print {
	.content-container {
		padding: 0!important;
	}
}
