@use './colors';

.overlay-dt-map.overlay-dt-map {
	// This value needs to be high because the 2d elements in the 3d view adjust their z-index automatically
	// in order to display on top of each other depending on the 3d position of the camera. With this value
	// the viewer supports 998 2d elements in the 3d view before they're rendered on top of this menus.
	z-index: 999;
	position: absolute;
}

building-viewer .dt-container .marker-cluster {
	display: flex;
	align-items: center;
	justify-content: space-around;
	box-sizing: border-box;
	height: 34px;
	width: 34px;
	background: #F5F8FFB4;
	border: 3px solid #F5F8FFB4;
	border-radius: 50%;
	color: colors.$accent;
	font-weight: 600;
	pointer-events: all;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transition-property: height, width;

	&:hover {
		height: 40px;
		width: 40px;
	}
}
