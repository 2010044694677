@use 'colors';
@use 'mixins';
@use 'spinner';
@use 'variables';
@use '@angular/material' as mat;

.au-assignee-tooltip.au-assignee-tooltip {
	bottom: -20px;
	position: relative;
}

.mat-drawer.mat-drawer-side.mat-drawer-side {
	border-right: 0;
}

.mat-mdc-tab-link {
	min-width: 80px !important;
}

.mat-mdc-menu-panel {
	box-shadow: 0 15px 30px rgba(96, 114, 158, 0.15) !important;
}

.mat-mdc-menu-content {
	.mat-mdc-menu-item {
		--mdc-typography-body1-font-size: 14px;
		--mdc-typography-body1-line-height: 24px;
		--mdc-typography-body1-font-weight: 400;
		--mdc-typography-body1-letter-spacing: normal;
	}
}

.au-mat-check-all-option {
	.mdc-form-field > label {
		padding-left: 5px;
		font-size: 18px;
		letter-spacing: normal;
	}
}

.mat-mdc-option {
	.mdc-list-item__primary-text.mdc-list-item__primary-text {
		font-size: 18px;
		letter-spacing: normal;
	}
}

.mat-mdc-autocomplete-panel.extra-height {
	max-height: 352px;
}

.mat-elevation-z4 {
	box-shadow: 0 15px 30px rgba(96, 114, 158, 0.15) !important;
}

.mat-elevation-z5 {
	box-shadow: 0 12px 30px rgba(96, 114, 158, 0.2) !important;
}

.au-modal-backdrop-transparent-bg {
	background-color: transparent;
}

.au-cmms-copy-dialog-backdrop {
	.mat-mdc-dialog-container {
		.mat-mdc-dialog-surface {
			border-radius: 8px !important;
			box-shadow: 0px 32px 56px -12px #54545429;
			border: 1px solid #e8e3da;
		}
	}
}
.au-cmms-action-center-backdrop {
	.mat-mdc-dialog-container {
		.mat-mdc-dialog-surface {
			border-radius: 10px !important;
			overflow-x: hidden !important;
		}
	}
}

.mat-fab--p0 {
	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	&.mat-mdc-fab .mat-button-wrapper {
		padding: 0;
	}
}

.sensors-tabs {
	height: calc(100% - 80px);

	.mat-mdc-tab {
		width: 50%;
	}

	.mdc-tab__text-label {
		font-size: mixins.px-to-rem(16);
		font-weight: 700;
	}
}

.au-snackbar {
	&.mat-mdc-snack-bar-container {
		width: 402px;
		max-width: inherit;
		min-height: inherit;
		padding: 0;
		border-radius: 8px;

		.mdc-snackbar__surface {
			background-color: initial;
			box-shadow: none;
		}

		.mdc-snackbar__label {
			padding-top: 0;
			padding-bottom: 0;
		}

		&.min-width {
			width: auto;
			min-width: 378px;
		}

		&.success {
			background: colors.$accent;
			box-shadow: 0 15px 25px rgba(58, 175, 140, 0.35);
		}

		&.warning {
			background: colors.$warning-background;
			box-shadow: 0 15px 25px rgba(247, 157, 107, 0.35);
		}

		&.error {
			background: colors.$thirtly-error;
			box-shadow: 0 15px 25px rgba(253, 117, 117, 0.35);
		}
	}

	.mat-mdc-progress-spinner circle,
	.mat-mdc-progress-spinner circle {
		stroke: #fff;
	}
}

.au-bottom-sheet {
	border-radius: 16px 16px 0 0;

	&.mat-bottom-sheet-container {
		height: 505px;
		padding: 14px 44px 33px;
	}
}

.expanded-chart {
	.mat-mdc-dialog-container {
		padding: 0;
	}
}

.day-calendar {
	&.mat-mdc-menu-panel {
		max-width: 360px;
	}
}

.spinner-wrapper {
	min-height: 170px;
	@extend %spinner-wrapper;
}

.sensor-actions-menu {
	.mat-icon {
		&.large {
			@include mixins.equal-size(32px);
			margin-right: 12px;
			margin-left: -4px;
		}
	}
}

.au-benchmark-tooltip {
	&.mat-mdc-tooltip {
		font-size: mixins.px-to-rem(16);
		line-height: mixins.px-to-rem(22);
		font-weight: bold;
		border: 1px solid #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
		padding: 4px 6px 3px 8px;
		position: relative;
		background: colors.$light-orange;
		color: colors.$light-orange-text;
	}

	&.red {
		background: colors.$light-red;
		color: colors.$error;
	}

	&.green {
		background: colors.$completed-background;
		color: colors.$completed-background-text;
	}
}

.last-request-menu {
	&.mat-mdc-menu-panel {
		max-width: 300px;
	}
}

.drag.sensor-element {
	cursor: pointer;
	position: relative;
	box-sizing: border-box;
	border-radius: 8px;
	background-color: colors.$background;
	color: colors.$secondary-text;
	font-size: variables.$base-font-size;
	margin-right: 16px;

	mat-icon {
		margin-right: 8px;
	}

	.sensor-name {
		position: relative;
	}

	&.drag {
		.drag-icon-container {
			padding: 14px 12px 11px 6px;
		}

		.content {
			padding: 14px 12px 11px 0;
		}
	}

	.close-icon {
		border-radius: 50%;
		color: colors.$error;
		background-color: colors.$error-toast;
		cursor: pointer;
		position: absolute;
		top: -6px;

		&:hover {
			background-color: colors.$error;
			color: #ffffff;
		}
	}

	&.cdk-drag-preview {
		.close-icon {
			display: none;
		}
	}
}

.au-assignee-select-menu.au-assignee-select-menu {
	margin-top: 12px;
	margin-left: -16px;
	border-radius: 16px;

	&.mat-mdc-menu-panel {
		min-width: 400px;
		max-width: 703px;
		max-height: 485px;
	}

	.mat-mdc-menu-content {
		padding: 20px 0;
	}
}

.au-button-no-shadow {
	box-shadow: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.au-mat-chip-list {
	.cmms-chip.cmms-chip {
		height: 29px;
		border-radius: 16px;
		padding: 6px 12px 6px 12px;
		font-size: 14px;
		font-weight: 400;
		.mat-chip-button {
			opacity: 1;
		}

		.mat-icon.mat-icon {
			color: colors.$error;
			height: 14px;
			width: 14px;
			font-size: 14px;
			position: relative;
			top: 2px;
		}
	}
}

.fake-action-wo-create {
	margin-top: 10px;
	margin-left: -20px;
}

.au-section-panel {
	background-color: var(--mat-sys-surface-container-lowest) !important;
	box-shadow: none !important;

	&.au-section-inherit-bg {
		background: inherit;
	}

	&.no-padding .mat-expansion-panel-body {
		padding: 0;
	}

	.mat-expansion-panel-body {
		padding-left: 0;
		padding-right: 0;
		background-color: var(--mat-sys-surface-container-lowest);
	}

	.section-header {
		padding: 0;

		&.mat-expanded {
			height: 48px;
		}

		&:hover {
			background-color: unset !important; // TODO need to remove important
		}
	}

	.section-name {
		display: inline-block;
		font-weight: 400;
		font-size: 16px;
		line-height: 30px;
	}

	.arrow {
		display: flex;
		top: 1px;
		position: relative;
		margin-right: 12px;
		transition: transform 100ms linear;

		.mat-icon {
			@include mixins.equal-size(20px);
		}

		&.rotate-right-90 {
			top: 0;
		}
	}

	.description {
		display: flex;
		justify-content: flex-end;
		margin: 0;
	}

	&.full-title-width {
		.mat-expansion-panel-header-title {
			flex-basis: initial;
			width: 100%;
		}
	}
}

.cmms-sort-menu.cmms-sort-menu {
	margin-top: 13px;

	&.mat-mdc-menu-panel {
		min-width: 340px;
	}
}

.cmms-filter-menu.cmms-filter-menu {
	margin-top: 13px;

	&.mat-mdc-menu-panel {
		min-width: 355px;
	}
}

.cmms-applied-filter-menu.cmms-applied-filter-menu {
	margin-top: 4px;

	&.mat-mdc-menu-panel {
		max-width: 500px;
		border-radius: 10px;
		overflow: hidden;
	}
}

.locations-list-container.locations-list-container {
	&.mat-mdc-menu-panel {
		max-width: 670px;
		border-radius: 8px;
	}
}

.au-cmms-select-dropdown.au-cmms-select-dropdown {
	margin-top: 4px;

	&.mat-mdc-menu-panel {
		min-width: 250px;
	}
}

.au-tooltip.au-tooltip {
	box-shadow: 0 12px 30px rgba(96, 114, 158, 0.2);
	border-radius: 8px;

	.mdc-tooltip__surface {
		font-size: 14px;
		line-height: 22px;
		padding: 24px;
		max-width: 500px;
	}
}

.mat-input-suffix,
.mat-mdc-form-field-icon-suffix.mat-mdc-form-field-icon-suffix {
	padding: 0 5px;
}

.label-tooltip.label-tooltip {
	.mdc-tooltip__surface {
		max-width: 250px;
	}
}

.mdc-text-field--filled .mdc-floating-label.mdc-floating-label--float-above {
	.small-above-label {
		font-size: 14px !important;
	}
}

.label-list-menu.label-list-menu {
	&.mat-mdc-menu-panel {
		min-width: 308px;
		box-sizing: border-box;
		max-height: 350px;
	}
}

.au-mat-menu-actions-list.au-mat-menu-actions-list {
	&.mat-mdc-menu-panel {
		border-radius: 8px;
	}
}

.mat-mdc-fab-base .mdc-button__label,
.mat-mdc-fab-base .mat-icon {
	z-index: 0 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
		.mat-mdc-option-multiple
	) {
	background-color: var(--mat-sys-surface-container-highest) !important;
}

.views-column-management-menu.views-column-management-menu {
	&.mat-mdc-menu-panel {
		max-width: 321px;
		border-radius: 8px;
	}
}

// Class overrides (set the class name to the component you want to override)

.hidden-mat-select-arrow {
	.mat-mdc-select-arrow-wrapper {
		visibility: hidden;
	}
}
.outlined-icon-btn {
	.mat-mdc-icon-button.mat-mdc-icon-button {
		border: 1px solid var(--mat-sys-outline);
	}
}

.row-reverse-list {
	.mdc-list-item--with-trailing-radio.mdc-list-item {
		flex-direction: row-reverse;
	}
}

//Material style overrides
:root {
	@include mat.dialog-overrides(
		(
			container-max-width: auto,
		)
	);
}
