@use 'colors';
@use 'mixins';
@use 'variables';
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * proxima-nova:
 *   - http://typekit.com/eulas/00000000000000003b9b306c
 *   - http://typekit.com/eulas/00000000000000003b9b3068
 *   - http://typekit.com/eulas/00000000000000003b9b3069
 *   - http://typekit.com/eulas/00000000000000003b9b306a
 *   - http://typekit.com/eulas/00000000000000003b9b306b
 *   - http://typekit.com/eulas/00000000000000003b9b305e
 *   - http://typekit.com/eulas/00000000000000003b9b305f
 *   - http://typekit.com/eulas/00000000000000003b9b3066
 *   - http://typekit.com/eulas/00000000000000003b9b3067
 *   - http://typekit.com/eulas/00000000000000003b9b3062
 *   - http://typekit.com/eulas/00000000000000003b9b3063
 *   - http://typekit.com/eulas/00000000000000003b9b3060
 *   - http://typekit.com/eulas/00000000000000003b9b3061
 *   - http://typekit.com/eulas/00000000000000003b9b3064
 *   - http://typekit.com/eulas/00000000000000003b9b3065
 * proxima-nova-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9b3078
 *   - http://typekit.com/eulas/00000000000000003b9b3079
 *   - http://typekit.com/eulas/00000000000000003b9b3076
 *   - http://typekit.com/eulas/00000000000000003b9b3072
 *   - http://typekit.com/eulas/00000000000000003b9b3073
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-04-17 17:12:23 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=vhc7sol&ht=tk&f=137.138.139.140.169.170.171.172.173.174.175.176.5474.5475.25136.25137.143.144.147.148.156.157.161.162.28165&a=30915150&app=typekit&e=css');

@font-face {
	font-family: 'proxima-nova';
	src:
		url('https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
			format('woff'),
		url('https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: 'proxima-nova';
	src:
		url('https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
			format('woff'),
		url('https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: 'proxima-nova';
	src:
		url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'proxima-nova';
	src:
		url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
			format('woff'),
		url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'proxima-nova';
	src:
		url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
			format('woff'),
		url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'proxima-nova';
	src:
		url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'proxima-nova';
	src:
		url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: 'proxima-nova-condensed';
	src:
		url('https://use.typekit.net/af/0ff5e1/00000000000000003b9b3078/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/0ff5e1/00000000000000003b9b3078/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/0ff5e1/00000000000000003b9b3078/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'proxima-nova-condensed';
	src:
		url('https://use.typekit.net/af/949eb9/00000000000000003b9b3076/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/949eb9/00000000000000003b9b3076/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
			format('woff'),
		url('https://use.typekit.net/af/949eb9/00000000000000003b9b3076/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'proxima-nova-condensed';
	src:
		url('https://use.typekit.net/af/8e2bbd/00000000000000003b9b3072/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/8e2bbd/00000000000000003b9b3072/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/8e2bbd/00000000000000003b9b3072/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}

%general-font {
	margin: 0;
}

h1 {
	@extend %general-font;
	font-family: variables.$secondary-font-family;
	font-size: mixins.px-to-rem(40);
	line-height: mixins.px-to-rem(54);
	font-weight: bold;
}

h2 {
	@extend %general-font;
	font-family: variables.$secondary-font-family;
	font-size: mixins.px-to-rem(32);
	line-height: mixins.px-to-rem(40);
	font-weight: bold;
}

h3 {
	@extend %general-font;
	font-family: variables.$secondary-font-family;
	font-size: mixins.px-to-rem(24);
	line-height: mixins.px-to-rem(27);
	font-weight: bold;
}

h4 {
	@extend %general-font;
	font-family: variables.$main-font-family;
	font-size: mixins.px-to-rem(18);
	line-height: mixins.px-to-rem(30);
	font-weight: bold;
}

h5 {
	@extend %general-font;
	font-family: variables.$main-font-family;
	font-size: mixins.px-to-rem(16);
	line-height: mixins.px-to-rem(22);
	font-weight: bold;
}

.link-button {
	font-family: variables.$main-font-family;
	font-size: mixins.px-to-rem(14);
	line-height: mixins.px-to-rem(22);

	&:hover {
		text-decoration: underline;
	}
}

.label {
	font-size: mixins.px-to-rem(14);
	line-height: mixins.px-to-rem(20);
	margin-top: 0;
	margin-bottom: 12px;
	display: block;

	&.horizontal {
		line-height: mixins.px-to-rem(24);
		margin-bottom: 0;
	}
}

.body-1 {
	font-family: variables.$main-font-family;
	font-size: mixins.px-to-rem(16);
	line-height: mixins.px-to-rem(30);
}

.body-2 {
	font-family: variables.$main-font-family;
	font-size: mixins.px-to-rem(14);
	line-height: mixins.px-to-rem(22);

	&.grey {
		color: colors.$secondary-text;
	}
}

.font-bold-small {
	font-size: small;
	font-weight: 700;
}

.font-bold-medium {
	font-size: medium;
	font-weight: 700;
}

.font-bold-large {
	font-size: large;
	font-weight: 700;
}

.font-title-large {
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
}
